import React from 'react';
import {graphql} from 'gatsby';
import Layout from './layout';
import ArticleList from '../components/ArticleList';
import {Helmet} from 'react-helmet';
import {FindArticleByTagQuery} from '../types/graphql-types';

type Props = {
  pageContext: {tag: string};
  data: FindArticleByTagQuery;
};

const Tags = ({
  pageContext: {tag},
  data: {
    allMarkdownRemark: {edges, totalCount},
  },
}: Props) => {
  return (
    <Layout>
      <Helmet>
        <title>noy72.com</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="ui mini statistics centered grid">
        <div className="statistic">
          <div className="value">{tag}</div>
          <div className="label">タグ</div>
        </div>
        <div className="statistic">
          <div className="value">{totalCount}</div>
          <div className="label">記事数</div>
        </div>
      </div>
      <ArticleList nodes={edges.map(edge => edge.node)} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query FindArticleByTag($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            description
            thumbnail
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`;

export default Tags;
