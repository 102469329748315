import React from 'react';
import {Link} from 'gatsby';
import TagsRow from './TagsRow';
import {Nodes} from '../types/manual-graphql-types';

const ArticleList = ({nodes}: {nodes: Nodes}) => (
  <div className="ui relaxed items">
    {nodes.map(node => (
      <div key={node.fields?.slug} className="item">
        <div className="thumbnail">
          {node.fields?.thumbnail && (
            <img src={node.fields.thumbnail} alt="thumbnail" loading="lazy" />
          )}
        </div>
        <div className="content">
          <Link to={`/${node.fields?.slug}`} className="grey-links">
            <div className="text--ssmall link">{node.frontmatter?.date}</div>
            <div className="text--xlarge text--bold link">
              {node.frontmatter?.title}
            </div>
            <div className="text--normal link">{node.fields?.description}</div>
          </Link>
          <TagsRow tags={node.frontmatter?.tags} />
        </div>
      </div>
    ))}
  </div>
);

export default ArticleList;
